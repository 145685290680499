/* CustomModal.css */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.custom-modal-content {
  background: white;
  padding: 20px;
  position: fixed;
  z-index: 6;
  width: 100vw;
  min-height: 100vh;
}

.close-button {
  position: fixed;
  font-size: 30px;
  cursor: pointer;
  z-index: 6;
  color: #474283;
  left: 50px;
}
