html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Share Tech Mono";
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  /* overflow-x:hidden ; */
  font-family: "Share Tech Mono";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  background-color: rgba(48, 48, 48, 1);
  z-index: -1;
}

.cl1 {
  background-color: #40e0d0;
}
.cl2 {
  background-color: #f7f9f9;
}
.cl3 {
  background-color: #ff5733;
}
.cl4 {
  background-color: #daf7a6;
}
.cl5 {
  background-color: #6495ed;
}
.cl6 {
  background-color: #800000;
}
.HeroBG {
  height: 100vh;
  z-index: 0;
  margin: 0;
  position: fixed;
  width: 100vw;
}
.Hero {
  z-index: 1;
  background-color: transparent;
  color: white;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.SiteBody {
  position: relative;
  height: 100%;
}
.HeroTextBig {
  /* margin-top: 45vh; */
  text-transform: uppercase;
  color: white;
  /* font-family: 'Saira'; */
  font-family: "Share Tech Mono";
  font-size: 90px;
}
.HeroTextMed {
  /* margin-top: 45vh; */
  text-transform: uppercase;
  color: white;
  /* font-family: 'Saira'; */
  font-family: "Share Tech Mono";
}

.Section {
  z-index: 1;
  background-color: rgba(48, 48, 48, 1);
  color: white;
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 200vh;
}
.Section2 {
  z-index: 3;
  background-color: rgba(48, 48, 48, 1);
  color: white;
  position: relative;
  top: 200vh;
  bottom: 0;
  right: 0;
  left: 0;
  height: 400vh;
}
.SectionBody {
  z-index: 1;
  position: relative;
  font-family: "Share Tech Mono";
  background-color: transparent;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  /* overflow-x: hidden; */
}
.title {
  text-transform: uppercase;
  font-size: 50px;
  font-family: "Share Tech Mono";
  word-spacing: normal;
}
.frame {
  border-color: transparent;
  border-radius: 40px;
}

.mac-monitor {
  width: 1200px;
  height: 700px;
  background-color: #d8d8d8;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  margin: 20px auto;
  position: relative;
  overflow: hidden;
}

.screen {
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  border: 10px solid #000;
  border-radius: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;
}

.screen iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.base {
  width: 100%;
  height: 30px;
  background-color: #d8d8d8;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apple-logo {
  width: 50px;
  height: auto;
  margin-bottom: 25px;
}

.SectionTitle {
  color: white;
  height: 100vh;

  z-index: 5;
  background-color: rgba(48, 48, 48, 1);

  -webkit-transition: 1s;

  transition: 1s;
}
.SectionDisplayTitle {
  font-size: 40px;
  font-family: "Share Tech Mono";
  text-transform: uppercase;
  text-align: right;
  z-index: 2;
  position: relative;
}

.SectionCount {
  font-size: 250px;
  font-family: "Share Tech Mono";
  position: relative;
  text-align: left;
  z-index: 2;
}

.SectionWork {
  font-size: 180px;
  font-family: "Share Tech Mono";

  position: absolute;
  width: 47vw;
  word-break: break-all;
  line-height: 150px;
  left: 1vw;

  top: 10vh;
  text-align: left;
  z-index: 2;
  color: rgba(255, 255, 255, 0);
  border-style: dotted;
  padding: 20px;
}
.SectionWorkDes {
  font-size: 17px;
  font-family: "Share Tech Mono";

  position: absolute;
  width: 40vw;
  word-break: break-all;
  /* line-height: 150px; */
  left: 48vw;

  top: 15vh;
  text-align: left;
  z-index: 2;
  color: rgba(255, 255, 255, 0);

  text-transform: uppercase;
  font-weight: 700;
}

.sectionCount {
  transition: 0.1s;
  font-size: 20px;
}
.sectionCount:hover {
  font-size: 25px;
}

.arrow {
  bottom: 0vh;
  -webkit-animation-name: arrowJump;
  animation-name: arrowJump;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: relative;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@-webkit-keyframes arrowJump {
  0% {
    bottom: 0vh;
  }
  50% {
    bottom: 1.5vh;
  }
  100% {
    bottom: 0vh;
  }
}

@keyframes arrowJump {
  0% {
    bottom: 0vh;
  }
  50% {
    bottom: 1.5vh;
  }
  100% {
    bottom: 0vh;
  }
}

.rotate {
  bottom: 0vh;
  -webkit-animation-name: arrowJump;
  animation-name: arrowJump;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: relative;
}

@keyframes arrowJump {
  0% {
    bottom: 0vh;
  }
  50% {
    bottom: 1.5vh;
  }
  100% {
    bottom: 0vh;
  }
}

.navbut {
  position: fixed;

  z-index: 4;
  font-size: 20px;
  height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: right;
  -webkit-transition: 1s;
  transition: 1s;
  right: 0;
  text-align: end;
  top: 10vh;
}

.navText {
  position: fixed;
  -webkit-transition: 1s;
  transition: 1s;
  top: 0;
  right: 0vw;
}

.li {
  mix-blend-mode: difference;
  z-index: 999999;
  font-size: 15px;
}

.slide {
  -webkit-transition: 1s;
  transition: 1s;
}

.dp {
  border: #f7f9f9;
  border-style: double;
  border-width: 6px;
  border-radius: 10px;
}
.dpName {
  text-align: left;
  font-size: 80px;
}
.dpText {
  text-align: left;
  font-size: 20px;
}

.heroNav {
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  border-bottom: transparent;
  background-color: transparent;

  color: white;
  transition: 0.3s;
}
.heroNav:hover {
  color: #0083e0;
}

.plainbut {
  border: transparent;
  background-color: transparent;
  border-width: 2px;
  font-size: 15px;
  transition: 0.1s;
  color: inherit;
  position: relative;
}
.plainbut:hover {
  color: #0083e0;
}
.plainbut_active {
  border-top: transparent;
  border-right: transparent;
  border-left: transparent;
  border-bottom: #0083e0;
  border-style: solid;
  border-width: 2px;
  font-size: 15px;
  transition: 0.1s;
}

a {
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  color: white;
}
a:hover {
  color: #0083e0;
}

.blogTitleImg {
  width: inherit;
  height: inherit;
  border-radius: 5px;
}
